import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";

import { useStyles } from "./ContactDetails.css";

class ContactDetails extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <h1>Let's Connect</h1>
        <p>Do you want to hire me? Please mail me to connect with me.</p>
        <div>
          <Link href="mailto:niteshkc101@gmail.com" color="inherit">
            <div style={{ fontSize: 20, fontWeight: "bold" }}>
              niteshkc101@gmail.com
            </div>
          </Link>
        </div>
      </div>
    );
  }
}

export default withStyles(useStyles)(ContactDetails);
