import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";

import LinkedInIcon from "@material-ui/icons/LinkedIn";
import GitHubIcon from "@material-ui/icons/GitHub";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";

import { useStyles } from "./Home.css";
import Profile from "./Profile/Profile";
import Projects from "./Projects/Projects";
import ContactDetails from "./ContactDetails/ContactDetails";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      links: [
        {
          name: "GitHub",
          link: "https://github.com/NIteshkc101",
          icon: <GitHubIcon />,
        },
        {
          name: "Linkedin",
          link: "https://www.linkedin.com/in/nitesh-kc-906489215/",
          icon: <LinkedInIcon />,
        },
        {
          name: "Instagram",
          link: "https://www.instagram.com/niteshkc101/",
          icon: <InstagramIcon />,
        },
        {
          name: "Facebook",
          link: "https://www.facebook.com/nitesh.kc.14/",
          icon: <FacebookIcon />,
        },
      ],
    };
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Profile links={this.state.links} />
        <Projects />
        <ContactDetails />
        <div style={{ textAlign: "center" }}>Thank you for scrolling.</div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {this.state.links.map((data, index) => (
            <div key={index} style={{ padding: 10 }}>
              <Link
                href={data.link}
                color="inherit"
                target="_blank"
                rel="noreferrer"
              >
                {data.icon}
              </Link>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default withStyles(useStyles)(Home);
