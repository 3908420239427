export const useStyles = (theme) => ({
  root: {
    paddingLeft: 50,
    paddingRight: 50,
    paddingBottom: 20,
    [theme.breakpoints.down("md")]: {
      padding: 10,
    },
  },
  paper: {
    borderRadius: 20,
  },
  linkStyle: { "&:hover": { textDecoration: "none" } },
  buttonStyle: {
    backgroundColor: "#fff !important",
    borderRadius: 20,
    color: "#181818",
    fontSize: 16,
    fontWeight: "bold",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#eee !important",
      borderColor: "#fff !important",
      color: "#000",
    },
  },
  gridContent: {
    width: "100%",
    textAlign: "center",
  },
  imageStyle: {
    height: 400,
    borderRadius: 10,
    [theme.breakpoints.down("md")]: {
      height: 300,
    },
  },
});
