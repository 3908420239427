export const useStyles = (theme) => ({
  root: {
    display: "flex",
    padding: 30,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      padding: 10,
    },
  },
  titleStyle: {
    fontSize: 50,
    fontWeight: "bold",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      fontSize: 40,
      textAlign: "left",
    },
  },
  body: {
    display: "flex",
    flexDirection: "column",
    fontSize: 20,
    "&#body1": {
      width: "40%",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    "&#body2": {
      width: "60%",
      textAlign: "justify",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
  },
  socialStyle: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
    },
  },
  linkStyle: {
    textAlign: "center",
    "&:hover": { cursor: "pointer" },
  },
});
