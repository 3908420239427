import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";

import { useStyles } from "./Profile.css";

class Profile extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.body} id="body1">
          <div className={classes.titleStyle}>
            Hello! <br /> <u>Nitesh KC</u> Here,
            <br />
          </div>
          <div style={{ fontSize: 25 }}>
            Android Developer | Python Developer
          </div>
        </div>

        <div className={classes.body} id="body2">
          <p>
            I previously worked as an android Developer. I worked as part time
            python developer as well. I am available for freelancing, a
            part-time job, and full-time jobs.
          </p>
          <Link href="/full" color="inherit" className={classes.linkStyle}>
            See My Full Details
          </Link>
        </div>

        <div className={classes.socialStyle}>
          {this.props.links.map((data, index) => (
            <div key={index} style={{ padding: 10 }}>
              <Link
                href={data.link}
                color="inherit"
                target="_blank"
                rel="noreferrer"
              >
                {data.icon}
              </Link>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default withStyles(useStyles)(Profile);
