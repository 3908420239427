import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import Book1 from "./Book1.jpeg";
import Book2 from "./Book2.jpeg";
import Garaz1 from "./Garaz1.jpeg";
import Garaz2 from "./Garaz2.jpeg";
import Garaz3 from "./Garaz3.jpeg";

import { useStyles } from "./Projects.css";

class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: [
        {
          name: "Garaz Services Mobile Application",
          description:
            "Developed an android application for automobile spare parts ecommerce and online request towing service",
          liveStatus: true,
          liveLink:
            "https://play.google.com/store/apps/details?id=com.droomi.iaeproject",
          images: [
            {
              icon: Garaz1,
            },
            {
              icon: Garaz2,
            },
            {
              icon: Garaz3,
            },
          ],
        },
        {
          name: "Book Ecommerce",
          description: "An android application for ecommerce of books.",
          liveStatus: false,
          images: [
            {
              icon: Book1,
            },
            {
              icon: Book2,
            },
          ],
        },
      ],
    };
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <h1>Work Portfolio</h1>
        {this.state.projects.map((project, index) => (
          <div key={index}>
            <h2 style={{ display: "flex" }}>
              <div>{index + 1}. </div>
              <u>{project.name}</u>
            </h2>
            <div>
              <p>{project.description}</p>
              {project.liveStatus && (
                <Button className={classes.buttonStyle}>
                  <Link
                    href={project.liveLink}
                    color="inherit"
                    target="_blank"
                    rel="noreferer"
                    className={classes.linkStyle}
                  >
                    See More
                  </Link>
                </Button>
              )}
              <br />
              <br />

              <Grid container spacing={3} className={classes.gridContent}>
                {project.images.map((image, index) => (
                  <Grid item xs={6} sm={6} md={4} xl={4} lg={4} key={index}>
                    <img
                      src={image.icon}
                      alt={project.name + index}
                      className={classes.imageStyle}
                    />
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default withStyles(useStyles)(Projects);
