export const useStyles = (theme) => ({
  root: {
    padding: 30,
    backgroundColor: "#181818",
    color: "#fff",
    minHeight: "100vh",
    [theme.breakpoints.down("md")]: {
      padding: 20,
    },
  },
  rootContent: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  bodyContent1: {
    width: "65%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  bodyContent2: {
    width: "35%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  body: {
    // float: "right",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      textAlign: "left",
    },
  },
  content1: {
    width: "75%",
    padding: 10,
    fontSize: 18,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  content2: {
    width: "25%",
    padding: 10,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  paper: {
    borderRadius: 20,
  },
  linkStyle: { "&:hover": { textDecoration: "none" } },
  buttonStyle: {
    backgroundColor: "#fff !important",
    borderRadius: 20,
    color: "#181818",
    fontSize: 16,
    fontWeight: "bold",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#eee !important",
      borderColor: "#fff !important",
      color: "#000",
    },
  },
  rootStrengthStyle: {
    display: "flex",
    alignItems: "left",
    justifyContent: "left",
    [theme.breakpoints.down("md")]: { flexDirection: "column" },
  },
  strengthStyle: {
    width: "50%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
});
