import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
// import Countdown from "../Components/Countdown/Countdown";
import FullDetails from "../Components/Home/FullDetails/FullDetails";
import Home from "../Components/Home/Home";

class Routes extends Component {
  render() {
    return (
      <Switch>
        <Route path="/" exact component={Home} />
        {/* <Route path="/home" exact component={Home} /> */}
        <Route path="/full" exact component={FullDetails} />
      </Switch>
    );
  }
}

export default Routes;
