import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import ListItem from "@material-ui/core/ListItem";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";
import HomeIcon from "@material-ui/icons/Home";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import EmailIcon from "@material-ui/icons/Email";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import GitHubIcon from "@material-ui/icons/GitHub";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";

import Nitesh from "./Assets/Nitesh.jpg";
import Java from "./Assets/Java.svg";
import Android from "./Assets/Android.svg";
import Kotlin from "./Assets/Kotlin.svg";
import Python from "./Assets/Python.svg";
import GitHub from "./Assets/GitHub.svg";

import { useStyles } from "./FullDetails.css";

class FullDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      personalDetails: [
        {
          icon: <PermContactCalendarIcon style={{ marginRight: 5 }} />,
          link: false,
          data: "Nitesh KC",
        },
        {
          icon: <HomeIcon style={{ marginRight: 5 }} />,
          link: false,
          data: "Dhapasi, Kathmandu",
        },
        {
          icon: <PhoneAndroidIcon style={{ marginRight: 5 }} />,
          link: false,
          data: "+977-9849799271",
        },
        {
          icon: <EmailIcon style={{ marginRight: 5 }} />,
          link: false,
          data: "niteshkc101@gmail.com",
        },
        {
          icon: <GitHubIcon style={{ marginRight: 5 }} />,
          link: true,
          data: "https://github.com/NIteshkc101",
        },
        {
          icon: <LinkedInIcon style={{ marginRight: 5 }} />,
          link: true,
          data: "https://www.linkedin.com/in/nitesh-kc-906489215/",
        },
        {
          icon: <FacebookIcon style={{ marginRight: 5 }} />,
          link: true,
          data: "https://www.facebook.com/nitesh.kc.14/",
        },
        {
          icon: <InstagramIcon style={{ marginRight: 5 }} />,
          link: true,
          data: "https://www.instagram.com/niteshkc101/",
        },
      ],
      itProficiency: [
        {
          name: "Java",
          icon: <img src={Java} alt="Java" style={{ width: 70, height: 70 }} />,
        },
        {
          name: "Kotlin",
          icon: (
            <img src={Kotlin} alt="Kotlin" style={{ width: 70, height: 70 }} />
          ),
        },
        {
          name: "Android",
          icon: (
            <img
              src={Android}
              alt="Android"
              style={{ width: 70, height: 70 }}
            />
          ),
        },
        {
          name: "Python",
          icon: (
            <img src={Python} alt="Python" style={{ width: 70, height: 70 }} />
          ),
        },
        {
          name: "GitHub",
          icon: (
            <img src={GitHub} alt="GitHub" style={{ width: 70, height: 70 }} />
          ),
        },
      ],
    };
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Link
          href="/"
          color="inherit"
          style={{ fontSize: 20, display: "flex", alignItems: "center" }}
        >
          <ArrowBackIcon />
          Go Back
        </Link>
        <h1>Full Details</h1>
        <div className={classes.rootContent}>
          <div className={classes.bodyContent1}>
            <h2>Personal Details</h2>
            <div className={classes.body}>
              <img
                src={Nitesh}
                alt="Nitesh"
                style={{ width: 300, height: 300, borderRadius: 20 }}
              />
              <div className={classes.content1}>
                {this.state.personalDetails.map((personal, index) => (
                  <ListItem key={index} style={{ display: "flex" }}>
                    {personal.icon}
                    {personal.link ? (
                      <Link
                        href={personal.data}
                        color="inherit"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {personal.data}
                      </Link>
                    ) : (
                      <div>{personal.data}</div>
                    )}
                  </ListItem>
                ))}
              </div>
            </div>
          </div>

          <div className={classes.bodyContent2}>
            <h2>IT Proficiency</h2>
            <Grid container spacing={3}>
              {this.state.itProficiency.map((it, index) => (
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={4}
                  xl={4}
                  lg={4}
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {it.icon}
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
        <Divider flexItem style={{ height: 1, backgroundColor: "#fff" }} />
        <div>
          <h2>Work Experience</h2>
          <ListItem style={{ display: "block" }}>
            <h3>Backend Developer</h3>
            <div>November 2019 - February 2020</div>
            <div>
              Worked as a Backend Developer reasearching about elastic
              algorithm.
            </div>
          </ListItem>
          <ListItem style={{ display: "block" }}>
            <h3>Android Developer</h3>
            <div>October 2020 - June 2021</div>
            <div>Worked as a native android developer.</div>
          </ListItem>
        </div>

        <Divider flexItem style={{ height: 1, backgroundColor: "#fff" }} />
        <div>
          <h2>Academics</h2>
          <ListItem style={{ display: "block" }}>
            <h3>Bachelor's Level</h3>
            <div>2015 (A.D.) - 2020 (A.D.)</div>
            <div>
              Completed bachelor level in 2020 from
              <b>National College of Computer Studies (NCCS)</b> in &nbsp;
              <b>
                Bachelor in Computer Science and Information Technology
                (BscCSIT)
              </b>
            </div>
          </ListItem>
          <ListItem style={{ display: "block" }}>
            <h3>Intermediate Level</h3>
            <div>2012 (A.D.) - 2015 (A.D.)</div>
            <div>
              Completed intermediate level / +2 level in 2015 from &nbsp;
              <b>HERALD COLLEGE</b> in &nbsp;
              <b>Science</b> faculty
            </div>
          </ListItem>
          <ListItem style={{ display: "block" }}>
            <h3>School Leaving Certificate </h3>
            <div> - 2013 (A.D.) </div>
            <div>
              completed School Leaving Certificate from &nbsp;
              <b>HERALD INTERNATIONAL SCHOOL</b>
              &nbsp; with first division with distinction
            </div>
          </ListItem>
        </div>

        <Divider flexItem style={{ height: 1, backgroundColor: "#fff" }} />

        <div className={classes.strengthStyle}>
          <div className={classes.strengthStyle}>
            <h3>Strengths</h3>
            <ul>
              <li>Pressure handling</li>
              <li>Hard and Smart Working</li>
              <li>Adaptability</li>
              <li>Team Work</li>
              <li>Dedicated</li>
              <li>Autodidactic</li>
            </ul>
          </div>
          <div className={classes.strengthStyle}>
            <h3>Weaknesses</h3>
            <ul>
              <li>
                Sometimes due to too much ambiguity, it will become difficult to
                perform the task.
              </li>
              <li>
                Sometimes it becames diffcult to maintain the work and personal
                life schedule.
              </li>
            </ul>
          </div>
        </div>

        <Divider flexItem style={{ height: 1, backgroundColor: "#fff" }} />
        <div>
          <h3>Work Portfolio</h3>
          <Link href="/" color="inherit">
            Go To Check Details
          </Link>
        </div>
      </div>
    );
  }
}

export default withStyles(useStyles)(FullDetails);
